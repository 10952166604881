.Roadmap {
    display:flex;
    align-items:center;
    flex-direction:column;
    color:#21272C;
    width:100vw;
}

.Roadmap h1 {
    font-size:64px;
    margin:0px;
}

.Roadmap p {
    font-size:18px;
    margin:0px;
}

.roadmap-header {
    display:flex;
    flex-direction:column;
    align-items:center;
    gap:16px;
    padding:64px;
    padding-top:144px;
    text-align:center;

}

.Roadmap button {
    cursor:pointer;
    border-radius: 8px;
    border: none;
    height: 40px;
    padding: 0px 16px;
    background-color: #FF4822;
    color: #ffffff;
    box-shadow: 0 4px 8px rgba(33, 39, 44, 0.25);
    font-size:14px;
    margin-top:16px;
}

.Roadmap button:hover {
    background-color: #e34222;
}

.roadmap-filters {
    display:flex;
    flex-direction:row;
    cursor:pointer;
    gap:16px;
    color:#627484;
}

.active {
    color: #21272c;
    text-decoration: none; /* Remove default underline */
    position: relative; /* Establish positioning context */

  }
  
  .active::after {
    content: ''; /* Create a pseudo-element for the underline */
    position: absolute; /* Position the underline absolutely */
    left: 0; /* Start the underline from the left edge of the link */
    bottom: 0; /* Position the underline at the baseline */
    width: 100%; /* Make the underline span the full width of the link */
    border-bottom: 2px solid rgb(255, 72, 34); /* Style the underline as needed */
  }
  
.roadmap-filters p {
    padding:14px;
}

.roadmap-table {
    background: linear-gradient(174.31deg, #ffffff -19.12%, #e9ecef 76.45%);

    box-sizing: border-box; /* Include padding in width calculation */
    background-color: #f0f0f0; /* Same background color as parent */
    width: 100%;
    padding:40px;

 
}

.roadmap-table-inner {
    display:flex;
    flex-wrap:wrap;

    gap:25px;
    width:calc(100vw - 80px);
    margin:0px;
    margin-right: -25px; /* Add this line to offset the last column gap */

}
