.DeletePopUp {
    width:400px;
    height:300px;
    background-color:white;
    border-radius:5px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;

}

.DeleteOuter {
    width:100vw;
    height:100vh;
    background-color:rgba(128, 128, 128, 0.221);
    position:fixed;
    top:0px;
    right:0px;
    display:flex;
    justify-content:center;
    align-items:center;
}

.DeletePopUp div {
    display:flex;
    flex-direction:row;
    gap:10px;
}

.DeletePopUp h4 {
    margin:0px;
}


.DeletePopUp div :first-child {
    height:35px;
    width:120px;
    border-radius: 6px;
    border:transparent;
    color:white;
    background-color:rgba(244, 33, 1, 0.784);
    cursor:pointer;
}

.DeletePopUp div button {
    height:35px;
    width:120px;
    border-radius: 6px;
    border:transparent;
    color:rgb(59, 58, 58);
    background-color:rgb(229, 227, 227);
    cursor:pointer;
}

.DeletePopUp p {
    font-size:14px;
}