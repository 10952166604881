/* Container to center the spinner */
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* You can adjust the height as needed */
  }
  
  /* Spinner animation */
  .loading-spinner {
    width: 100px; /* Adjust the size of the spinner */
    height: 100px;
    border: 8px solid #f3f3f3; /* Light gray border */
    border-top: 8px solid #e87a20; /* Blue border for animation */
    border-radius: 50%;
    animation: spin 1s linear infinite; /* Animation name, duration, and iteration count */
  }
  
  /* Spinner animation keyframes */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }