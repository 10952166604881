@media only screen and (max-width: 700px) {
    .Settings-Profile {
        margin:40px 10% 10px 10%;
    }
}

@media only screen and (min-width: 700px) {
.Settings-Profile {
    margin: 120px 0px 0px 120px;
    position:relative;
}
}

@media only screen and (max-width: 360px) {
    .Update-password input {
        width:80vw;
        height:50px;
        border-radius:4px;
        border:0.5px solid grey;
        padding-left:15px;
    }
    .Update-email input {
        height:50px;
        width:80vw;
        border-radius:4px;
        border:0.5px solid grey;
        padding-left:15px;
    }
}    

.settings-header {
    margin-top:50px;
}

.Update-email form {
    display:flex;
    flex-direction:row;
    align-items:flex-end;
    gap:50px;
}

@media screen and (max-width:950px) {
    .Update-email form {
        flex-direction:column;
        align-items:flex-start;
        gap:15px;
    }
    
}

.active-save-email {
    height:35px;
    width:120px;
    border-radius: 6px;
    border:transparent;
    color:white;
    background-color:rgb(30, 32, 49);
    cursor:pointer;
}

.inactive-save-email {
    height:35px;
    width:120px;
    border-radius: 6px;
    cursor: not-allowed;
}

.Email-input {
    display:flex;
    flex-direction:column;
    gap:3px;
}

.Email-input label {
    font-size:14px;
}

@media only screen and (min-width: 360px) {
.Update-password input {
    height:50px;
    width:300px;
    border-radius:4px;
    border:0.5px solid grey;
    padding-left:15px;
}
.Update-email input {
    height:50px;
    width:300px;
    border-radius:4px;
    border:0.5px solid grey;
    padding-left:15px;
}
}

.Update-password {
    display:flex;
    flex-direction:row;
    align-items:flex-end;
    gap:50px;
}

@media screen and (max-width:950px) {
    .Update-password {
        flex-direction:column;
        align-items:flex-start;
        gap:15px;

    }
    
}

.active-save-password {
    height:35px;
    width:120px;
    border-radius: 6px;
    border:transparent;
    color:white;
    background-color:rgb(30, 32, 49);
    cursor:pointer;
}

.inactive-save-password {
    height:35px;
    width:120px;
    border-radius: 6px;
    cursor: not-allowed;
}

.Password-input {
    display:flex;
    flex-direction:column;
    gap:15px;
}

.Password-input div {
    display:flex;
    flex-direction:column;
    gap:3px;
}

.Password-input label {
    font-size:14px;
}

.password-error {
    font-size:12px;
    color:red;
}

.Delete-account button {
    height:35px;
    width:120px;
    border-radius: 6px;
    border:transparent;
    color:white;
    background-color:rgba(244, 33, 1, 0.575);
    cursor:pointer;
}

.error-messaging {
    font-size:12px;
    color:red;
}

.success-container {
    position:fixed;
    top:30px;
    width:400px;
    background-color: rgba(0, 255, 255, 0.276);
    border-radius:30px;
    color:#333333;
    display:flex;
    justify-content: center;
}

