.navbar2 {
    margin: auto;
    font-family: sans-serif;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    position:fixed;
    background-color: white;
    width:100%;
    top:0;
}

.navbar1 {
    box-shadow: 0px 0.01px 20px 0.01px #cdcdcd;
    margin: auto;
    font-family: sans-serif;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    position:fixed;
    background-color: white;
    width:100%;
    top:0;
}
	
.nav_list {
    display:flex;
    flex-direction:row;
    align-items:center;
}

.nav_list > li {
    list-style-type:none;  
    font-family: Inter V,sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    padding:0px 20px 0px 20px;
}

.nav_list > li > a {
    text-decoration:none;
    color: #43485E;
    
}

.log_in {
    color: #1E3CC0 !important;
}

.nav_list > li > a > button {
    text-decoration:none;
    color: #ffffff;
    font-size:15px;
    font-family:Inter V,sans-serif;
}

.nav_list > li > a > button {
    background-color:#EF896C;
    border-radius: 6px;
    width: 120px;
    height: 45px;
    left: 1238px;
    top: 21px;
    border:none;
    cursor:pointer;
    margin-right:20px;
    letter-spacing:0.5px;
}

.nav_list > li > a > button:hover {
    background-color:#ec6a46;
}

.nav_img {
    margin-left:30px;
}