.Navigation ul {
    display:flex;
    flex-direction:row;
    gap:20px;
    padding-left:20px;
}

.Navigation ul li {
    list-style:none;
}

.Navigation ul a {
    text-decoration: none;
}

.nav-active {
    font-weight:600;
    color:black;
}

.nav-inactive {
    color:#627484;
}

.Navigation {
    display:flex;
    width:100%vw;
    height:80px;
    align-items:center;
    position:fixed;
    top:0px;
    width:100vw;
    background: rgba(255, 255, 255, 0.3); /* Semi-transparent background color */
    backdrop-filter: blur(10px);
    z-index:50;
}

.Navigation img {
    position:fixed;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
}

