.roadmap-pop-up-table {
    width:100vw;
    height:100vh;
    position:fixed;
    background-color: rgba(236, 230, 229, 0.3);
    z-index:101;
    top:0%;
    left:0%;
    cursor:default;
}

.Roadmap_Card_Pop_Up {
    position:fixed;
    background-color: white;
    width:80vw;
    height:80vh;
    top:10%;
    left:10%;
    z-index:101;
    border-radius:15px;
    cursor:pointer;
}

.Roadmap_Card_Pop_Up_Top_Container {
    height:50vh;
    overflow:hidden;
}

.Roadmap_Card_Pop_Up_Top {
    border-radius:15px 15px 0px 0px;
    max-width:100%;
    object-fit:contain;
}