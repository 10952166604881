.Onboarding {
    padding:40px;
    height:100vh;
    width:100vw;
    background-color:#f6f5f7;
    display:flex;
    justify-content: center;
    align-items: flex-start;
}

.Onboarding_Card {
    background-color:white;
    border-radius: 10px;
  	box-shadow: 0 14px 28px rgba(0,0,0,0.25), 
			0 10px 10px rgba(0,0,0,0.22);
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    padding:30px;
    width:100%;
    max-width:800px;
}

.Onboarding_Card h3 {
    margin:0px;
    margin-bottom:30px;
    font-size:20px;
    color:rgb(45, 45, 45);
    margin-top: -20px;

}

.form {
    width:100%;
    display:flex;
    flex-direction:column;
    gap:10px;
}

.Onboarding_Card label {
    font-size: 12px;
    font-weight:700;
}

.Onboarding_Card input {
    height:40px;
    border-radius:5px;
    border-width: 1px;
    border-color:rgb(128 128 128 / 36%);
    padding-left:20px;
    color:grey;
}

.one-input {
    display:flex;
    flex-direction:column;
    width:100%;
    gap: 5px;
}

.domain {
    width:100%;
}

.domain input {
    width:100%;
}

.two-input {
    display:flex;
    flex-direction:row;
    width:100%;
    gap:30px;
}

.Onboarding_Card button {
    cursor:pointer;
    border-radius: 8px;
    border: none;
    height: 40px;
    padding: 0px 16px;
    background-color: #FF4822;
    color: #ffffff;
    box-shadow: 0 4px 8px rgba(33, 39, 44, 0.25);
    font-size:14px;
    margin-top:16px;
}

.Onboarding_Card button:hover {
    background-color: #e34222;
}

.domain {
    display:flex;
    flex-direction:row;
    align-items: center;
}

.domain p {
    margin:0px;
    margin-right:10px;
}

@media only screen and (max-width: 600px) {
    .two-input {
        flex-direction:column;
    }
}

@media only screen and (max-width: 350px) {
        .domain p {
            font-size:14px;        
    }
}

@media only screen and (max-width: 330px) {
    .domain {
        flex-direction:column;
        align-items: flex-start;        
}
}