.DashboardDropDown {
    background-color:white;
    border-radius:10px;
    padding:10px;
    display:flex;
    flex-direction:column;
    justify-content:space-around;
    border:1px solid rgba(0, 0, 0, 0.181);
    font-size:14px;
    color:#333333;
    height: 220px;
    width:180px;
    z-index: 999999999;
    position:fixed;

}

@media only screen and (max-width: 950px) {
    .DashboardDropDown {
        width:100vw;
        height:100vh;
        position:fixed;
        top:0%;
        right:0%;
    }

}

@media only screen and (min-width: 950px) {
.Close-Dashboard {
    display: none !important;
}
}

.Close-Dashboard {
    position:absolute;
    font-size:20px;
    top:20px;
    right:20px;
}

.DashboardDropDown * {
    height:calc(220px/5);
    display:flex;
    align-items: center;
    cursor: pointer;
}

.DashboardDropDown *:hover {
    background-color: #007bff21;
}

.feedback-link {
    text-decoration:none;
    color:#333333;
}

.feedback-link p:hover {
    background-color: transparent;
}