@media only screen and (min-width: 820px) {
    .Login-mobile {
        display:none;
    }
    
}

@media only screen and (max-width: 820px) {
    .Login-mobile {
        display:flex;
    }
    
}

@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');

* {
	box-sizing: border-box;
}

button {
	cursor:pointer;
}

.Login-mobile {
	background: #f6f5f7;
	justify-content: flex-start;
	align-items: center;
    flex-direction:column;
	font-family: 'Montserrat', sans-serif;
	height: 100vh;
    padding:0px 40px 0px 40px;
    gap:40px;
}

.Login-mobile h1 {
	font-weight: bold;
	margin: 0;
    font-size:5vw;
}

.Login-mobile h2 {
	text-align: center;
}

.Login-mobile p {
	font-size: 14px;
	font-weight: 100;
	line-height: 20px;
	letter-spacing: 0.5px;
	margin: 20px 0 30px;
}

.Login-mobile span {
	font-size: 12px;
}

.Login-mobile a {
	color: #333;
	font-size: 14px;
	text-decoration: none;
	margin: 15px 0;
}

.Login-mobile button {
	border-radius: 20px;
	border: 1px solid #FF4B2B;
	background-color: #FF4B2B;
	color: #FFFFFF;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: transform 80ms ease-in;
}

.Login-mobile button:active {
	transform: scale(0.95);
}

.Login-mobile button:focus {
	outline: none;
}

.Login-mobile button.ghost {
	background-color: transparent;
	border-color: #FFFFFF;
}

.Login-mobile form {
	background-color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 20px 20px;
	height: 100%;
	text-align: center;
    border-radius:12px;
}

.Login-mobile input {
	background-color: #eee;
	border: none;
	padding: 12px 15px;
	margin: 8px 0;
	width: 100%;
}


.sign-in-container-mobile {
    width:100%;
}
.sign-up-container-mobile {
    width:100%;
}


.social-container {
	margin: 20px 0;
}

.social-container a {
	border: 1px solid #DDDDDD;
	border-radius: 50%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin: 0 5px;
	height: 40px;
	width: 40px;
}

.login-logo-mobile {
    width:120px;
}
