.PasswordInput {
    width:100vw;
    height:100vh;
    display:flex;
    justify-content: center;
    align-items:center;
    flex-direction:column;
    gap:30px;
}

.PasswordInput input {
    height:40px;
    border-radius:5px;
    border-width: 1px;
    border-color:rgb(128 128 128 / 36%);
    padding-left:20px;
    color:grey;
}

.PasswordInput div {
    display:flex;
    flex-direction:row;
    margin:0px 40px 0px 40px;
    text-align: center;

}

.PasswordInput form {
    gap:30px;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content: center;
    margin:0px 40px 0px 40px;

}

.PasswordInput button {
    margin:0px;
}

.error-message {
    color:red;
}