.Feedback_Pop_Up {
    background-color: white;
    width:95vw;
    height:85vh;
    z-index:101;
    border-radius:15px;
    cursor:pointer;
    text-align:left;
    padding-top: 30px;
    padding-left:40px;
    padding-right:40px;
    cursor:default;
    max-width:854px;
    position:relative;
}

.exit {
    cursor:pointer;
}

.Feedback_Pop_Up_Header {
    display:flex;
    flex-direction:row;
    justify-content:space-between;
}

.Feedback_Pop_Up_Text {
    padding: 12px 24px;
    background-color: rgb(248, 249, 250);
    border: 1px solid rgb(148, 166, 184);
    border-radius: 8px;
    font-family: Inter, "SF Pro Display", -apple-system, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgb(10, 28, 51);
}

.Feedback_Pop_Up_Text p {
    font-size:14px;
}

.main-feedback-form {
    display:flex;
    flex-direction:column;
    padding-top:30px;
    font-size:14px;
}

.main-feedback-form input {
    height:120px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    width: 100%;
    resize: none;
    display: block;
    position: relative;
    border: 0px;
    padding: 8px 12px;
    border-radius: 8px;
    box-shadow: inset 0 0 0 1px var(--box-shadow-color, #E4E7EC);
    caret-color: rgb(49, 142, 255);
    
}

@media only screen and (min-width: 800px) {
.Feedback_Pop_Up button {
    position:absolute;
    bottom:40px;
    right:50px;
    width:140px;
}
}

.roadmap-pop-up-table {
    height:100vh;
    width:100vw;
    background-color:rgba(231, 129, 13, 0.46);
    display:flex;
    align-items: center;
    justify-content: center;
}
