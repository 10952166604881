.FeedbackSuccess_Background {
    display:flex;
    width:100vw;
    height:100vh;
    justify-content:center;
    align-items:center;
    position:absolute;
    background-color: rgba(128, 128, 128, 0.171);
}

.Feedback_Success {
    width:300px;
    height:200px;
    background-color: white;
    border-radius:5px;
    display:flex;
    justify-content: center;
    align-items:center;
    position:relative;
}

.exit {
    position:absolute;
    top:10px;
    left:10px;
    margin:0px;
}