@media only screen and (min-width: 700px) {
  
.Settings {
    display: flex;
    flex-direction: row;
  }

  .back-to-dashboard {
    position:absolute;
    top:20px;
    left:20px;
  }

  .back-to-dashboard button {
    height:30px;
    width:140px;
    font-size:12px;
    border-radius:6px;
    border: 1px solid rgb(5, 5, 38);
    background-color:transparent;
    color:rgb(5, 5, 38);
    cursor:pointer;
  }
  
  .left-settings {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    border-width: 0px 0.5px 0px 0px;
    border-color: #bdbdbd9e;
    border-style: solid;
    padding: 60px 30px 0px 30px;
    margin-top:40px;
    margin-bottom:40px;
    height:calc(100vh - 80px);
    width:25%;
  }

  .right-settings {
    width:75%;
  }

  .left-settings-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    gap: 20px;
  }

  .left-settings-inner h2 {
    padding-left:10px;
  }

  .left-settings-inner button {
    cursor:pointer;
    font-size:16px;
    padding:6px 12px 6px 12px;
  }

  .active-setting {
    text-align:start;
    border-radius:20px;
    background-color: rgba(152, 150, 150, 0.124);
    border: 0.5px solid rgba(227, 225, 225, 0.989);
  }

  .inactive-setting {
    background-color:transparent;
    text-align:start;
    border-width:0px;
  }
}

@media only screen and (max-width: 700px) {
  .back-to-dashboard {
    position:absolute;
    top:20px;
    left:20px;
  }

  .back-to-dashboard button {
    height:30px;
    width:140px;
    font-size:12px;
    border-radius:6px;
    border: 1px solid rgb(5, 5, 38);
    background-color:transparent;
    color:rgb(5, 5, 38);
    cursor:pointer;
  }
  .right-settings {
    width:100%;
  }

  .left-settings {
    margin:100px 10% 10px 10%;
    padding-bottom:10px;
    border-bottom: 1px solid grey;
  }
  .left-settings-inner button {
    cursor:pointer;
    font-size:16px;
    padding:6px 12px 6px 12px;
  }
  .active-setting {
    text-align:start;
    border-radius:20px;
    background-color: rgba(152, 150, 150, 0.124);
    border: 0.5px solid rgba(227, 225, 225, 0.989);
  }

  .inactive-setting {
    background-color:transparent;
    text-align:start;
    border-width:0px;
  }
}