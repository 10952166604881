.DropDown {
    background-color:white;
    border-radius:10px;
    padding:10px;
    position:absolute;
    top:38px;
    right:46px;
    display:flex;
    flex-direction:column;
    justify-content:space-around;
    border:1px solid rgba(0, 0, 0, 0.181);
    font-size:14px;
    height:90px;
    width:160px;
    color:#333333;
}

.DropDown * {
    cursor:pointer;
    display:flex;
    align-items: center;
    cursor: pointer;
    height:calc(70px/2);
    margin:0px;
}

.DropDown *:hover {
    background-color: #007bff21;
}

.Account-link {
    color:#333333;
    text-decoration:none;
}

.Account-link:hover {
    background-color: transparent;
}