.Roadmap_Card {

    background-color:white;
    cursor: pointer;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.06), 0 1px 3px rgba(0, 0, 0, 0.16);
    border-radius: 8px;

    height:fixed;
    min-width:350px;
    flex:1;
    overflow:hidden;

    display:flex;
    flex-direction:column;
    justify-content:space-between;

}

@media (max-width: 805px) {
    .Roadmap_Card {
      max-width: 100%; /* 1 per row */
    }
}

@media screen and (min-width: 806px) and (max-width: 1179px) {
    .Roadmap_Card {
      max-width: calc(50% - 12.5px); /* 2 per row */
    }
}

@media screen and (min-width: 1180px) and (max-width: 1554px) {
    .Roadmap_Card {
      max-width: calc(33.33% - 16.67px); /* 3 per row */
    }
}

@media screen and (min-width: 1555px) and (max-width: 1929px) {
    .Roadmap_Card {
      max-width: calc(25% - 18.75px); /* 4 per row */
    }
}

@media screen and (min-width: 1930px) and (max-width: 2304px) {
    .Roadmap_Card {
      max-width: calc(20% - 20px); /* 5 per row */
    }
}

@media screen and (min-width: 2305px) and (max-width: 2679px) {
    .Roadmap_Card {
      max-width: calc(16.67% - 20.83px); /* 6 per row */
    }
}

@media screen and (min-width: 2680px) {
    .Roadmap_Card {
      max-width: calc(14.29% - 21.43px); /* 7 per row */
    }
}

.Roadmap_Card_Title {
    display:flex;
    flex-direction:row;
    align-items:center;
    height:100px;
    padding: 0px 24px 0px 24px;
    font-size:18px;
    font-weight:700;
    color: #1b1f22;
}

.Roadmap_Card_Image {
    background-color:rgb(228, 230, 253);
    max-width: 100%; /* Limit the image width to 100% of the container's width */
     max-height: 100%; /* Limit the image height to 100% of the container's height */
    object-fit: contain;
}