.Password_Background {
    background-color: rgba(232, 133, 20, 0.355);
    position:absolute;
    right:0;
    left:0;
    top:0;
    bottom:0;
    display:flex;
    justify-content:center;
    align-items:center;
}

.PasswordChange {
    background-color:white;
    width:35%;
    min-width:250px;
    height:50%;
    border-radius:20px;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
    position:relative;
    padding:20px;
}

.password_change_form {
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
    width:100%;
}

.password_change_form input {
    height:40px;
    border-radius: 6px;
    border: 1px solid rgba(128, 128, 128, 0.568);
    padding-left:10px;
}

.PasswordChange h3 {
    color:rgb(65, 64, 64);
    margin-bottom:40px;
}

.password_change_inputs {
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
    gap: 20px;
    width: 100%;
}

.password_change_inputs input {
    width:100%;
}

.password_change_button {
    cursor:pointer;
    border-radius: 8px;
    border: none;
    height: 40px;
    padding: 0px 16px;
    background-color: #FF4822;
    color: #ffffff;
    box-shadow: 0 4px 8px rgba(33, 39, 44, 0.25);
    font-size:14px;
    margin-top:56px;
}

.password_change_close_button {
    cursor:pointer;
    background-color: #ff472200;
    border:0px;
    font-size:20px;
    position:absolute;
    top:20px;
    left:20px;
}