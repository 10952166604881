.AccountNavigation {
    display:flex;
    width:100%vw;
    height:80px;
    align-items:center;
    top:0px;
    width:100vw;
    justify-content: flex-end;
    padding-right: 40px;
    background-color:white;
}

.AccountNavigation a {
    text-decoration:none;
    color:black;
}
