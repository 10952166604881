.Director {
    background-color:#f6f5f7;
    height:100vh;
    width:100vw;

}

.Dashboards {
    overflow-x:auto;
}

.Dashboard-container {
    background-color:white;
    border-radius:6px;
    border: 1px solid rgba(0, 0, 0, 0.16);
    max-height:80vh;
    margin:20px;
    font-size:16px;
    min-width: 850px; /* Set a fixed width or use a percentage */
}

.Dashboards ul {
    margin:0px;
    padding:0px;
}

.Dashboards h2 {
    margin-left:20px;
    font-size:22px;
}

.headers {
    display:flex;
    flex-direction:row;
    text-decoration:none;
    height:60px;
    align-items:center;
    list-style-type: none;
}

.title-header {
    width:20%;
    padding-left:40px;
}

.live-header {
    width:20%;
}

.password-header {
    width:20%;
}

.link-header {
    width:20%;
}

.action-header {
    width:20%;
}

.title-row {
    width:20%;
    padding-left:40px;
}

.title-row-edit {
    font-size:14px;
    width:200px;
    height:28px;
    border-radius:4px;
    padding-left:10px;
    border:1px solid rgba(128, 128, 128, 0.78);
}

.title-row-edit-container {
    width:20%;
    padding-left:40px;
}

.live-row {
    width:20%;
}

.live-row button {
    width:110px;
    height:28px;
    font-size:13px;
    color:white;
    border:0px;
    border-radius:3px;
    font-weight:500;
}

.live {
    background-color:rgb(91, 169, 91);
}

.not-live {
    background-color:rgb(169, 94, 91);
}

.password-row {
    width:20%;
}

.link-row {
    width:20%;
    display:flex;
    flex-direction:row;
    align-items:center;
    gap:10px;
}

.link-row a {
    display:flex;
    align-items:center;
    color:black;
}

.link-row-edit {
    font-size:14px;
    width:120px;
    height:28px;
    border-radius:4px;
    padding-left:10px;
    border:1px solid rgba(128, 128, 128, 0.78);
}

.av-unav {
    font-size:10px;
}

.av {
    color:green
}

.unav {
    color:red;
}

.av-unav-container {
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
}

.action-row {
    width:20%;
}

.row {
    display:flex;
    flex-direction:row;
    align-items:center;
    height:80px;
    border-top: 1px solid rgba(0, 0, 0, 0.215);
}

.row p {
    margin:0px;
}

.table-buttons {
    display:flex;
    flex-direction:row;
    gap:10px;
}

.table-submit {
    cursor:pointer;
    border-radius: 8px;
    border: none;
    height: 28px;
    width:70px;
    background-color: #FF4822;
    color: #ffffff;
    font-size:12px;
}

.table-submit:hover {
    background-color: #e34222;
}

.table-submit-disabled {
    cursor:not-allowed;
    border-radius: 8px;
    border: none;
    height: 28px;
    width:70px;
    background-color: #4a494961;
    color: #333333;
    font-size:12px; 
}

.table-cancel {
    height:28px;
    width:70px;
    font-size:12px;
    border-radius:6px;
    border: 1px solid rgb(5, 5, 38);
    background-color:transparent;
    color:rgb(5, 5, 38);
    cursor:pointer;
}

.table-cancel:hover {
    background-color: rgb(228, 223, 223);
}

.button_to_onboarding_container {
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:flex-end;
    padding-right:20px;
}

.button_to_onboarding_container button {
    border-radius: 8px;
    border: none;
    height: 40px;
    padding: 0px 16px;
    color: #ffffff;
    box-shadow: 0 4px 8px rgba(33, 39, 44, 0.25);
    font-size:14px;
    margin-top:16px;
    z-index:1;
}

.enabled-portal-button {
    background-color: #FF4822;
    cursor:pointer;
}

.disabled-portal-button {
    background-color: #a09e9e;
    cursor:not-allowed;

}

.enabled-portal-button:hover {
    background-color: #e34222;
}

.billing-top-messaging p {
    background-color: rgba(0, 255, 255, 0.276);
    padding:20px;
    border-radius:30px;
    color:#333333;
    display: inline-block;
    margin:20px;
}