@media only screen and (max-width: 700px) {
    .Settings-Billing {
        margin:40px 10% 10px 10%;
    }
}

@media only screen and (min-width: 700px) {
.Settings-Billing {
    margin: 120px 10% 0px 120px;
    position:relative;
}
}

.billing-top-messaging p {
    background-color: rgba(0, 255, 255, 0.276);
    padding:20px;
    border-radius:30px;
    color:#333333;
    display: inline-block;
}

.checkout-managebilling {
    height:35px;
    width:120px;
    border-radius:6px;
    border: 1px solid rgb(5, 5, 38);
    background-color:transparent;
    color:rgb(5, 5, 38);
    cursor:pointer;
}