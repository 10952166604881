.Forgot{
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    height:100vh;
    width:100vw;
}



@media only screen and (max-width: 410px) {
    .forgot-password-form {
        width:90vw;
        display:flex;
        flex-direction:column;
        justify-content: center;
        align-items: flex-start;
    }

    .forgot-password-button {
        width:90vw;
        height:40px;
        color:white;
        cursor:pointer;
        border-radius: 3px;
        border: none;
        padding: 0px 16px;
        background-color: #f76a4e;
        color: #ffffff;
        font-size:13px;
        margin-top:16px;
    }

    .forgot-password-button-disabled {
        width:90vw;
        height:40px;
        color:rgb(95, 95, 95);
        border-radius: 3px;
        border: none;
        padding: 0px 16px;
        background-color: #d5d2d2;
        font-size:13px;
        margin-top:16px;
        cursor:not-allowed;
    }

    .forgot-password-form input {
        width:90vw;
        height:40px;
        border-radius: 3px;
        color:#333333;
        border:1px solid #3333335d;
        padding-left:10px;
    }
    .forgot-logo {
        width:40vw;
    }
}    

.forgot-password-form p {
    font-size:14px;
    color:#333333;
    padding-bottom:10px;
    text-align: center;
}

.forgot-password-button:hover {
    background-color: #FF4822;

}



@media only screen and (min-width: 410px) {
.forgot-password-form input {
    width:380px;
    height:40px;
    border-radius: 3px;
    color:#333333;
    border:1px solid #3333335d;
    padding-left:10px;
}
.forgot-password-form {
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: flex-start;
    width:380px;
}
.forgot-password-button-disabled {
    width:380px;
    height:40px;
    color:rgb(95, 95, 95);
    border-radius: 3px;
    border: none;
    padding: 0px 16px;
    background-color: #d5d2d2;
    font-size:13px;
    margin-top:16px;
    cursor:not-allowed;
}
.forgot-password-button {
    width:380px;
    height:40px;
    color:white;
    cursor:pointer;
    border-radius: 3px;
    border: none;
    padding: 0px 16px;
    background-color: #f76a4e;
    color: #ffffff;
    font-size:13px;
    margin-top:16px;
}
.forgot-logo {
    width:120px;
}
}

.forgot-password-form label {
    font-size:12px;
    padding-bottom:10px;
    color:#333333
}

.no-account {
    font-size:14px;
    color:#333333;
    padding-bottom:10px;
    margin-top:100px;
}

.Sign-up-link {
    color:#FF4822
}

.message-response {
    background-color: rgba(0, 255, 255, 0.276);
    padding:20px;
    border-radius:30px;
    color:#333333;
}